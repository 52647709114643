import { withTranslation, WithTranslation } from "react-i18next";
import user from "../../assets/user.png";

interface ReviewsPropsType extends WithTranslation {
  ref: React.RefObject<HTMLDivElement> | React.RefObject<null>;
}

const Reviews = ({ t, ref }: ReviewsPropsType) => {
  return (
    <div className="reviews" ref={ref}>
      <h2 className="reviews__title" ref={ref}>{t("reviews")}</h2>
      <div className="reviews__wrapper">
        <div className="reviews__box">
          <div className="reviews__user">
            <img className="reviews__picture" src={user} alt="user" />
            <p className="reviews__name">Bożena Milewska</p>
          </div>
          <p className="reviews__content">{t("userDescriptionOne")}</p>
        </div>
        <div className="reviews__box">
          <div className="reviews__user">
            <img className="reviews__picture" src={user} alt="user" />
            <p className="reviews__name">Piotr Nowak</p>
          </div>
          <p className="reviews__content">{t("userDescriptionTwo")}</p>
        </div>
        <div className="reviews__box">
          <div className="reviews__user">
            <img className="reviews__picture" src={user} alt="user" />
            <p className="reviews__name">Maria Wiśniewska</p>
          </div>
          <p className="reviews__content">{t("userDescriptionThree")}</p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Reviews);
