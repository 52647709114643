import { forwardRef } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";

interface ContactPropsType extends WithTranslation {
  entryRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  aboutUsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  contactRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  reviewsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  scrollToSection: (
    ref: React.RefObject<null> | React.RefObject<HTMLDivElement>,
    offset: number
  ) => void;
  openModal: () => void;
}

const Contact = forwardRef<HTMLDivElement, ContactPropsType>(
  ({
    t,
    entryRef,
    aboutUsRef,
    contactRef,
    reviewsRef,
    scrollToSection,
    openModal,
  }) => {
    return (
      <div className="contact" ref={contactRef}>
        <div className="contact__container">
          <h2 className="contact__title">{t("address")}</h2>
          <p className="contact__text">Srebrna 10</p>
          <p className="contact__text">Gdańsk, 00-001</p>
          <div className="contact__box">
            <h2 className="contact__title">{t("contact")}</h2>
            <p className="contact__text">kontakt@vesselhost.pl</p>
            <p className="contact__text">123456789</p>
          </div>
        </div>
        <div className="contact__container">
          <h2 className="contact__title">{t("menu")}</h2>
          <ul className="contact__list">
            <li
              className="contact__item"
              onClick={() => scrollToSection(entryRef, 80)}
            >
              {t("homePage")}
            </li>
            <li
              className="contact__item"
              onClick={() => scrollToSection(aboutUsRef, 80)}
            >
              {t("aboutUs")}
            </li>
            <li
              className="contact__item"
              onClick={() => scrollToSection(reviewsRef, 80)}
            >
              {t("reviews")}
            </li>
            <li className="contact__item" onClick={openModal}>
              {t("priceList")}
            </li>
            <li
              className="contact__item"
              onClick={() => scrollToSection(contactRef, 80)}
            >
              {t("contact")}
            </li>
          </ul>
        </div>
        <div className="contact__container">
          <h2 className="contact__title">{t("follow")}</h2>
          <div className="contact__socialMedia">
            <a
              className="contact__link"
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="contact__icon" src={facebook} alt="facebook" />
            </a>
            <a
              className="contact__link"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="contact__icon" src={instagram} alt="instagram" />
            </a>
            <a
              className="contact__link"
              href="https://www.youtube.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="contact__icon" src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    );
  }
);

export default withTranslation()(Contact);
