import { withTranslation, WithTranslation } from "react-i18next";
import products from "../../assets/product.png";

interface EntryPropsType extends WithTranslation {
  ref: React.RefObject<HTMLDivElement> | React.RefObject<null>;
}

const Entry = ({ t, ref }: EntryPropsType) => {
  return (
    <div className="entry" ref={ref}>
      <h1 className="entry__title">{t("support")}</h1>
      <p className="entry__desciption">{t("mainDescription")}</p>
      <div className="entry__box">
        <div className="entry__boxChild">
        <a
          className="entry__link"
          href="https://shipculture-eae76.web.app/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="entry__icon" src={products} alt="products"/>
          {t("crm")}
        </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Entry);
