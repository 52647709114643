import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { WithTranslation, withTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Statistics = ({t}: WithTranslation) => {
  const statisticsRef = useRef<(HTMLParagraphElement | null)[]>([]);

  useEffect(() => {
    statisticsRef.current.forEach((el) => {
      if (el) {
        const targetValue = parseInt(el.dataset.target || '0', 10);

        gsap.fromTo(
          el,
          { innerText: 0 },
          {
            innerText: targetValue,
            duration: 2,
            ease: 'power1.out',
            snap: { innerText: 1 },
            scrollTrigger: {
              trigger: el,
              start: 'top 80%',
              toggleActions: 'play reset play reset',
              onLeaveBack: () => gsap.set(el, { innerText: 0 }),
            },
            onUpdate: function () {
              el.innerText = Math.floor(this.targets()[0].innerText).toString();
            },
          }
        );
      }
    });
  }, []);

  return (
    <div className="statistics">
      <div className="statistics__box">
        <p
          className="statistics__number"
          ref={(el) => {
            statisticsRef.current[0] = el;
          }}
          data-target="6"
        >
          0
        </p>
        <p className="statistics__text">{t("specialists")}</p>
      </div>
      <div className="statistics__box">
        <p
          className="statistics__number"
          ref={(el) => {
            statisticsRef.current[1] = el;
          }}
          data-target="5"
        >
          0
        </p>
        <p className="statistics__text">{t("yearsOfProfessional")}</p>
      </div>
      <div className="statistics__box">
        <p
          className="statistics__number"
          ref={(el) => {
            statisticsRef.current[2] = el;
          }}
          data-target="22"
        >
          0
        </p>
        <p className="statistics__text">{t("satisfiedCustomers")}</p>
      </div>
      <div className="statistics__box">
        <p
          className="statistics__number"
          ref={(el) => {
            statisticsRef.current[3] = el;
          }}
          data-target="4"
        >
          0
        </p>
        <p className="statistics__text">{t("originalProjects")}</p>
      </div>
    </div>
  );
};

export default withTranslation()(Statistics);
