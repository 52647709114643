import React, { ReactNode } from 'react';

interface SkeletonPropsType {
  children?: ReactNode;
}

const Skeleton: React.FC<SkeletonPropsType> = ({ children }) => {
  return <>{children || null}</>;
};

export default Skeleton;
