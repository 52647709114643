import { withTranslation, WithTranslation } from "react-i18next";
import unsplashImage from "../../assets/unsplash-image.webp";

const General = ({ t }: WithTranslation) => {
  return (
    <div className="general">
      <div className="general__container">
        <div className="general__box">
          <h2 className="general__title">{t("dedicated")}</h2>
          <p className="general__description">{t("dedicatedDescription")}</p>
        </div>
        <div className="general__box">
          <h2 className="general__title">{t("recruitment")}</h2>
          <p className="general__description">{t("recruitmentDescription")}</p>
        </div>
      </div>
      <div className="general__container">
        <img
          className="general__image"
          src={unsplashImage}
          alt="unsplashImage"
        />
      </div>
      <div className="general__container">
        <div className="general__box">
          <h2 className="general__title">{t("industry")}</h2>
          <p className="general__description">{t("industryDescription")}</p>
        </div>
        <div className="general__box">
          <h2 className="general__title">{t("development")}</h2>
          <p className="general__description">{t("developmentDescription")}</p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(General);
