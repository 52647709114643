import { withTranslation, WithTranslation } from "react-i18next";

interface AboutUsPropsType extends WithTranslation {
  ref: React.RefObject<HTMLDivElement> | React.RefObject<null>;
}

const AboutUs = ({t, ref}: AboutUsPropsType) => {
  return (
    <div className="aboutUs" ref={ref}>
      <h2 className="aboutUs__title">{t("aboutUs")}</h2>
      <p className="aboutUs__description">
        {t("vesselhost")}
      </p>
    </div>
  );
};

export default withTranslation()(AboutUs);
