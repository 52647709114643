import { withTranslation, WithTranslation } from "react-i18next";

interface MenuPropsType extends WithTranslation {
  isMenu: boolean;
  entryRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  aboutUsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  reviewsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  contactRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  scrollToSection: (ref: React.RefObject<null> | React.RefObject<HTMLDivElement>, offset: number) => void;
  openModal: () => void;
}

const Menu = ({t, isMenu, entryRef, aboutUsRef, reviewsRef, contactRef, scrollToSection, openModal }: MenuPropsType) => {
  const isMenuOpen = isMenu ? "menuOpen" : "menuClose";

  return (
    <div className={isMenuOpen}>
      <ul className={`${isMenuOpen}__list`}>
        <li className={`${isMenuOpen}__item`} onClick={() => scrollToSection(entryRef, 80)}>{t("homePage")}</li>
        <li className={`${isMenuOpen}__item`} onClick={() => scrollToSection(aboutUsRef, 80)}>{t("aboutUs")}</li>
        <li className={`${isMenuOpen}__item`} onClick={openModal}>{t("priceList")}</li>
        <li className={`${isMenuOpen}__item`} onClick={() => scrollToSection(reviewsRef, 80)}>{t("reviews")}</li>
        <li className={`${isMenuOpen}__item`} onClick={() => scrollToSection(contactRef, 80)}>{t("contact")}</li>
      </ul>
    </div>
  );
};

export default withTranslation()(Menu);
